import React, { useEffect, useState } from "react";
import AdminDashboard from "./admindashboard";
import axios from "../api/baseurl";
import { useNavigate } from "react-router-dom";
import "./Spinner.css";
import "./Loader.css";
import UploadReport from "./uploadReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FiInfo } from "react-icons/fi";
import TestForPackage from "./testsByPackagePopup";
import FamilyAppointmentCancel from "./familyAppointmentCancelPopup";
import AppointmentReschedule from "./appointmentReschedule";

function EmployeesAppointmentsDetails() {
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState(0);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isReschedulePopupOpen, setIsReschedulePopupOpen] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    const orgcode = JSON.parse(localStorage.getItem("data")).orgcode;
    setLoader(true);
    try {
      const response = await axios.get(`/api/employeeappointment/labcode=${orgcode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("Fetched appointment data:", response.data);
      setAppointmentDetails(response.data);
      setFilteredData(response.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      handleError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleCancelClick = async (appointment) => {
    setSelectedAppointment(appointment)
    setIsCancelPopupOpen(true);
  };
  
  const handleCloseCancelPopup = () => {
    setIsCancelPopupOpen(false);
    getData();
  };


  const handleUploadReport = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsUploadModalOpen(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
    getData();
  };
 
  const [pdfUrl, setPdfUrl] = useState(null);
  const handleViewPdf = async (appointmentId) => {
  const token = JSON.parse(localStorage.getItem("data")).token;
  await axios({
    url: `/api/employeeappointment/download/id=${appointmentId}`,
    method: "GET",
    responseType: "blob",
    headers: { Authorization: `Bearer ${token}` },
  }).then((response) => {
    console.log("filedata;", response);
    const contentType = response.headers["content-type"];
    let extension = "";

    // Determine the file extension based on content type
    if (contentType.includes("application/pdf")) {
      extension = "pdf";
    } else if (contentType.includes("image/jpeg")) {
      extension = "jpg";
    } else if (contentType.includes("image/png")) {
      extension = "png";
    } else {
      console.error("Unsupported file type:", contentType);
      return;
    }

    // Create a blob URL and download link for the file
    const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `file.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
}


  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    console.log("Search term:", term); // Debug log
    const filtered = appointmentDetails.filter(
      (appointment) => {
        const companyName= appointment.company?.companyname?.toLowerCase();
        const packageName = appointment.pack?.packagename?.toLowerCase();
        const phoneNo = String(appointment.emplo?.phoneno)?.toLowerCase(); // Convert phone number to string
        return companyName.includes(term) || phoneNo.includes(term)|| packageName.includes(term);
      }
    );
    console.log("Filtered data:", filtered); // Debug log
    setFilteredData(filtered);
  };

  function handleError(error) {
    if (error.response) {
      if (error.response.status === 401) {
        navigate(`/signin`);
        toast.error("Your session has expired. Please login.");
      } else if (error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      }  else if (error.response.data && error.response.data.code) {
        toast.error(error.response.data.code);
      }
       else {
        toast.error("An error occurred.");
      }
    } else if (error.message) {
      toast.error(error.message);
    } else {
      toast.error("An error occurred.");
    }
  }


  const [isTestsModalOpen, setIsTestsModalOpen] = useState(false);
  const [tests, setTests] = useState([]);

  const openTestsModal = async (pkg) => {
    setLoader(true);
    try {
      const token = JSON.parse(localStorage.getItem("data")).token;
      const response = await axios.get(
        `/api/packagetestmapping/packageid=${pkg}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTests(response.data);

      setIsTestsModalOpen(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
     handleError(error);
    }
  };
  
  const closeDenominationModal = () => {
    setIsTestsModalOpen(false);
   
  };

  const handleAcceptAppointment = async (appointmentId) => {
    const token = JSON.parse(localStorage.getItem("data")).token;
    setLoader(true);
    try {
      await axios.post(
        "/api/employeeappointment/accept-or-reschedule",
        {
          id: `${appointmentId}`,
          appointmentstatus: "APPOINTMENT CONFIRMED",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      toast.success("Employee Appointment Confirmed successfully.");
      getData();
      setLoader(false);
    } catch (error) {
    setLoader(false);
      handleError(error);
 
    }
  };

  const handleRescheduleAppointment = (appointmentId) => {
    setSelectedAppointmentId(appointmentId);
    setIsReschedulePopupOpen(true);
  };

  const handleCloseReschedulePopup = () => {
    setIsReschedulePopupOpen(false);
    getData();
  };

  return (
    <div>
      <div>
        <AdminDashboard />
      </div>
      <div className="col-12 mx-xl-auto ps-xl-10 mt-10">
        <div className="card card-raised">
          <div className="card-body">
            <div className="card-title form-group d-flex align-items-center justify-content-between">
              <span>Appointment Details</span>
              <div className="form-group position-relative" style={{ width: '300px' }}>
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search by company code or phone number or Package"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{ paddingLeft: "2rem", width: "100%" }}
                />
                <span className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }}>
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
            </div>
            <br />
            <table className="table table-bordered">
                <thead>
                  <tr className="table-header">
                  <th>Name</th>
                  <th>Date</th>
                  {/* <th>Time</th> */}
                  <th>Company</th>
                  <th>Contact Number</th>
                  <th>Package Name</th>
                  <th>Status</th>
                  <th>Report</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length === 0 ? (
                  <tr>
                    <td colSpan="10" className="text-center">
                      No Data Available
                    </td>
                  </tr>
                ) : (
                  filteredData.map((appointment) => (
                    <tr key={appointment.id}>
                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        title="Upload Report"
                        onClick={() => handleUploadReport(appointment.id)}
                        onMouseOver={(e) => {
                          e.target.style.color = "blue";
                          e.target.style.textDecoration = "underline";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.color = "";
                          e.target.style.textDecoration = "";
                        }}
                      >
                         {appointment.fam?.name ? (
                          appointment.fam.name
                        ) : (
                          <>
                          {appointment.emplo?.name} {appointment.emplo?.lastname}
                        </>
                      )}
                      </td>
                      <td>{appointment.date}</td>
                      {/* <td>{appointment.time}</td> */}
                      <td>{appointment.company.companyname}</td>
                      <td>{appointment.emplo?.phoneno}</td>
                      <td style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{appointment.pack?.packagename}</span>
              <FiInfo
                 onClick={() => openTestsModal(appointment.packageid)}
                style={{
                  cursor: 'pointer'
                }}
              />
                      </td>
                      <td>
  <div>{appointment.appointmentstatus}</div>
  {/* {(appointment.appointmentstatus!=='CANCELLED'||appointment.appStatus?.appointmentStatus !== 'CANCELLED') || (appointment.appointmentstatus!=='COMPLETED'||appointment.appStatus?.appointmentStatus !== 'COMPLETED') && ( */}
  {(appointment.appointmentstatus!=='CANCELLED') && (appointment.appointmentstatus!=='COMPLETED') && (
  <>
    <div>
      {(appointment.appStatus?.appointmentStatus === 'APPOINTMENT BOOKED' ||appointment.appointmentstatus === 'PENDING')|| 
       (appointment.appStatus?.appointmentStatus === 'APPOINTMENT RESCHEDULED' && 
        (appointment.appStatus?.updatedBy !== 'Lab')&&(appointment.appStatus?.updatedBy !== 'Lab Corporate')) ? (
        <button
          className="btn btn-success btn-sm rounded-pill me-2"
          onClick={() => handleAcceptAppointment(appointment.id)}
        >
          Accept
        </button>
      ) : null}

      <button
        className="btn btn-danger btn-sm rounded-pill"
        onClick={() => handleCancelClick(appointment)}
      >
        Cancel
      </button>
    </div>
    <div className="mt-2">
      <button
        className="btn btn-warning btn-sm rounded-pill"
        onClick={() => handleRescheduleAppointment(appointment.id)}
      >
        Reschedule
      </button>
    </div>
  </>
)}
</td>

                      <td>
                        {appointment.file ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => handleViewPdf(appointment.id)}
                          >
                            View
                          </button>
                        ) : (
                          "No Report Added"
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        {isUploadModalOpen && (
          <UploadReport
            isOpen={isUploadModalOpen}
            onRequestClose={handleCloseUploadModal}
            empId={selectedAppointmentId}
          />
        )}

{isTestsModalOpen && (
<TestForPackage
        isOpen={isTestsModalOpen}
        onRequestClose={closeDenominationModal}
        testDetails={tests}
      />
)}

{isCancelPopupOpen &&(
   <FamilyAppointmentCancel
   isOpen={isCancelPopupOpen}
   onRequestClose={handleCloseCancelPopup}
   appointment={selectedAppointment}
 />
)}
  {isReschedulePopupOpen && (
          <AppointmentReschedule
            isOpen={isReschedulePopupOpen}
            onRequestClose={handleCloseReschedulePopup}
            appointmentId={selectedAppointmentId}
          />
        )}

        {loader && <div className="loading"></div>}
      </div>
    </div>
  );
}

export default EmployeesAppointmentsDetails;
