import React, { useState } from "react";
import { useNavigate} from "react-router-dom";
import axios from "../api/baseurl";
import { toast} from "react-toastify";
import "./Spinner.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button } from "react-bootstrap";

const CreateAdminAccount = ({ isOpen, onRequestClose }) => {
    const navigate = useNavigate();

    const url = "/api/createAdminAccount";
    const [admin, setAdmin] = useState(
      {
       username:"",
       role:"ROLE_ADMIN",
       accBio:{
        email:"",
        phone:"",
        firstname:"",
        lastname:"",
       }
        },
    );
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("data")).token;
        try {
          const emailCheckResponse = await axios.get(`/api/accounts/getByEmail=${admin.username}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          if (emailCheckResponse.data) {
            toast.error("Email already exist.");
            setLoading(false);
            return;
          }
        } catch (error) {
          handleError(error);
          setLoading(false);
          return;
        }
        axios
          .post(url, {
            username: admin.username,
            role: "ROLE_ADMIN",
            accBio:{
                email:admin.username,
                phone:admin.phone,
                firstname:admin.firstname,
                lastname:admin.lastname,
               }
          }, {
              headers: { Authorization: `Bearer ${token}` }
          })
          .then((res) => {
            setLoading(false);
          
            onRequestClose();
            setAdmin("");
            toast("Admin account created successfully and Password sent to their email");
          })
          .catch((error) => {
            setLoading(false);
            handleError(error);
            setAdmin("");
          });
    
    }

    function handleError(error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate(`/signin`);
          toast.error("Your session has expired. Please login.");
        } else if (error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        }  else if (error.response.data && error.response.data.code) {
          toast.error(error.response.data.code);
        }
         else {
          toast.error("An error occurred.");
        }
      } else if (error.message) {
        toast.error(error.message);
      } else {
        toast.error("An error occurred.");
      }
    }
  
    function rhandle(e) {
      const { id, value } = e.target;
      setAdmin((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    }
    return (
      <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
      <Modal.Title>Create Admin Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="col-xl-10 mx-xl-auto">
            <div className="card card-raised mb-3">
              <div className="card-body p-4 ">
              <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <label for="username">Email <span style={{color:"red"}}>* </span>
                               </label>
                              <input
                                className="form-control"
                                id="username"
                                type="email"
                                placeholder="username"
                                value={admin.username}
                                onChange={(e) => rhandle(e)}
                                required
                              />
                     </div>
                 
               
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="firstname">First Name <span style={{color:"red"}}>* </span></label>
                      <input
                        className="form-control"
                        id="firstname"
                        type="text"
                        placeholder="First Name"
                        value={admin.firstname}
                        onChange={(e) => rhandle(e)}
                        required
                      />
                  
                 </div>
            
                 
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        className="form-control"
                        id="lastname"
                        type="text"
                        placeholder="Last Name"
                        value={admin.lastname}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="contact">Contact Number <span style={{color:"red"}}>* </span></label>
                      <input
                        className="form-control"
                        id="phone"
                        type="text"
                        placeholder="contact number"
                        value={admin.phone}
                        onChange={(e) => rhandle(e)}
                        required
                      />
                    </div>
               
                  </div>
{/*   
                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pan">PAN</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        placeholder="PAN"
                        value={companyDetails.pan}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
  
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="gstin">GSTIN</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        placeholder="GSTIN"
                        value={companyDetails.gstin}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
  
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="tan">TAN</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        placeholder="TAN"
                        value={companyDetails.tan}
                        onChange={(e) => rhandle(e)}
                      />
                      
                    </div>
                  </div>
                  </div>
                  <br/>
                  <div><h5>Company Address:</h5></div>
               
                  <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressline1">Address Line1</label>
                      <input
                        className="form-control"
                        id="addressLine1"
                        type="text"
                        placeholder="Address"
                        value={companyDetails.addressLine1}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
  
                  <div className="col-md-6">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="addressline2">Address Line2</label>
                      <input
                        className="form-control"
                        id="addressLine2"
                        type="text"
                        placeholder="Address"
                        value={companyDetails.addressLine2}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
                  </div>
  
                  <div className="row">
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="city">City</label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        placeholder="City"
                        value={companyDetails.city}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
  
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pincode">Pincode</label>
                      <input
                        className="form-control"
                        id="pincode"
                        type="text"
                        placeholder="Pincode"
                        value={companyDetails.pincode}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
  
                  <div className="col-md-4">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="state">State</label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        placeholder="State"
                        value={companyDetails.state}
                        onChange={(e) => rhandle(e)}
                      />
                    </div>
                  </div>
                  </div> */}
                  
                  <input
                    type="submit"
                    value="SAVE"
                    className="btn btn-primary"
                    disabled={loading}
  
                  />
                   &nbsp;&nbsp;&nbsp;&nbsp;
  
                  <Button variant="secondary" onClick={onRequestClose}>
                 Close
                 </Button>  
  
                </form>
              </div>
            </div>
          </div>
                </Modal.Body>
        <Modal.Footer>
         </Modal.Footer>
      </Modal>
  );
}
export default CreateAdminAccount;
